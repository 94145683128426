<template>
  <div
    class="app-alert-box"
    :class="{ 'is-error': isError }"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
    @click="alertBoxStore.toggleAlertbox(true)"
    @keydown="alertBoxStore.toggleAlertbox(true)"
  >
    <div class="app-alert-box-slot">
      <div class="app-alert-box__content">
        <IonIcon :name="isError ? 'error-triangle' : 'success-check'" />
        <AtomHeading :text="text" class="app-alert-box__text" font-size="h3" />
      </div>
    </div>
  </div>
</template>

<script setup>
const alertBoxStore = useAlertBoxStore();
const { text, isError } = alertBoxStore.alertBoxContent;
</script>

<style lang="scss" scoped>
.app-alert-box {
    @include z-index('alertBox');
    @include fluid('padding-right', 47px, 47px);
    @include fluid('padding-left', 47px, 47px);
    @include fluid('padding-top', 27px, 27px);
    @include fluid('padding-bottom', 27px, 27px);

    position: fixed;
    top: 0;
    right: var(--page-padding);
    left: var(--page-padding);
    width: calc(100% - var(--page-padding) - var(--page-padding));
    max-width: 836px;
    border-radius: 0 0 12px 12px;
    margin: auto;
    background: var(--c-white);
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    color: var(--c-green);
    cursor: pointer;

    &.is-error {
        color: var(--c-primary);
    }
}

.app-alert-box-slot {
    @include fluid('column-gap', 20px, 20px);
    display: flex;
    width: 100%;
}

.app-alert-box__content {
    @include fluid('row-gap', 20px, 20px);
    display: flex;
    width: 100%;
    align-items: center;
    font-family: var(--f-family--primary);
    font-weight: var(--f-fw-primary--regular);
    gap: 40px;
}

.app-alert-box__icon {
    @include fluid('right', 36px, 56px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .app-icon {
        width: 50px;
        @include fluid('max-width', 35px, 50px);
    }
}
</style>
