<template>
  <div class="confirm-popup">
    <div class="confirm-popup__overlay" />
    <div ref="popupRef" class="confirm-popup__window">
      <div class="confirm-popup__window-content">
        <AtomHeading :text="popupStore.contentTitle" class="confirm-popup__window-content-title" html-tag="h1" font-size="h1" />
        <AtomRichtext :html="popupStore.contentText" class="confirm-popup__window-content-text" />
        <div class="confirm-popup__window-buttons">
          <AtomButton
            v-if="popupStore.showCancel"
            :text="popupStore.cancelText"
            is-inverted
            @click="popupStore.closePopup"
            @keydown="popupStore.closePopup"
          />
          <AtomButton
            v-if="popupStore.showConfirm"
            :text="popupStore.confirmText"
            @click="popupStore.confirm"
            @keydown="popupStore.confirm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

/*
    Stores
*/
const popupStore = useConfirmPopupStore();

/*
    Close popup on click outside
*/
const popupRef = ref(null);
onClickOutside(popupRef, () => {
    popupStore.closePopup();
});

/*
    Close on route change
*/
const route = useRoute();
watch(() => route.path, () => {
    popupStore.closePopup();
});
</script>

<style lang="scss" scoped>
.confirm-popup__overlay {
    @include z-index('confirmPopupOverlay');
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
}
.confirm-popup__window {
    @include z-index('confirmPopup');
    position: fixed;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    display: flex;
    width: 530px;
    height: 197px;
    box-sizing: content-box;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 134px;
    border-radius: 12px;
    background: var(--c-white);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.10);
    gap: 30px;
    transform: translate(-50%, -50%);
}

.confirm-popup__window-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.1));

    @include mobile {
        width: 100%;
    }
}

.confirm-popup__window-buttons {
    @include fluid('column-gap', 10px, 20px);
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 30px;
    row-gap: 10px
}
</style>
